import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext } from 'react';
import { SimpleFilterContext } from '../../contexts/simpleFilterContext';
import { breakpoints } from '../../styles/atoms/breakpoints';
import Icon from '../../styles/atoms/icons';
import { DropdownItem } from '../Inputs/Select/Select.styles';
import { FilterItem, SimpleFilterStyle } from './SimpleFilter.styles';

const SimpleFilter = ({ filters }) => {
  const { simpleFilterState, simpleFilterDispatch } = useContext(
    SimpleFilterContext
  );
  const isLargeScreen = useMediaQuery(`(min-width: ${breakpoints.md})`);

  const handleClick = id => {
    simpleFilterDispatch({ payload: id });
  };

  const handleChange = event => {
    simpleFilterDispatch({ payload: event.target.value });
  };

  const newFilters = [{ id: 'all', heading: 'View All' }, ...filters];

  return (
    <SimpleFilterStyle>
      {filters && isLargeScreen && (
        <>
          <p className="filterHeader">Categories</p>
          {newFilters.map(filter => (
            <FilterItem
              key={filter.id}
              selected={simpleFilterState.filter === filter.id}
              onClick={() => handleClick(filter.id)}
              to="accordionComponent"
              spy
              smooth
              duration={150}
              exact="true"
              offset={-170}
              activeClass="active"
            >
              {filter.heading}
            </FilterItem>
          ))}
        </>
      )}
      {filters && !isLargeScreen && (
        <FormControl variant="outlined">
          <Select
            value={simpleFilterState.filter}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {newFilters.map(filter => (
              <DropdownItem key={filter.id} value={filter.id}>
                {filter.heading}
                <Icon id="FiCheck" size={20} />
              </DropdownItem>
            ))}
          </Select>
        </FormControl>
      )}
    </SimpleFilterStyle>
  );
};

export default SimpleFilter;
